import React, { useEffect, useState, Suspense } from 'react';
import LinkMaker from '../components/LinkMaker';
import UploadFile from '../components/UploadFile';
import Ads from '../components/Ads';
import Loader from '../components/Loader';

function HomePage() {
  


  return (
    <div className="HomePage container page">

      <div className="row mt-5">
        <div className="col link-maker-col">
          <div className="link-maker-wrapper">
            {/* @ts-ignore */}
            <LinkMaker />
            {/* <Suspense fallback={<Loader />}>
            </Suspense> */}
          </div>
        </div>
        <div className="col upload-file-col">
          <div className="file-upload-wrapper">
            {/* @ts-ignore */}
            <UploadFile />
            {/* <Suspense fallback={<Loader />}>
            </Suspense> */}
          </div>
        </div>
      </div>
      {/* <Ads /> */}
    </div>
  );
}

export default HomePage;
