import React, { useRef, useState, useEffect } from 'react';
import SimplePeer from 'simple-peer';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { generateID } from '../utils/helpers';
import { copyTextToClipboard } from '../utils/helpers';
import { BACKEND_URL } from '../utils/keys';
import { useSocket } from '../context/SocketProvider';
import { peerConfig } from '../config/WebRTCPeer';
import Loader from './Loader';

function UploadFile() {
  const socket = useSocket();
  const [searchParams, setSearchParams] = useSearchParams();

  // Reference
  const inputEl = useRef(null);
  const peerInstance = useRef(null);

  // Local state
  const [fileURL, setFileURL] = useState<string | null>(null);
  const [joinedRoomId, setJoinedRoomId] = useState<null | string>('');
  const [error, setError] = useState<null | string>('');
  const [multiUserConnection, setMultiUserConnection] = useState<boolean>(
    false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // State for file upload
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFilename, setUploadedFilename] = useState<null | string>('');
  const [peerSignal, setPeerSignal] = useState(null);
  const [gettingOffer, setGettingOffer] = useState<boolean>(false);

  // https://www.youtube.com/watch?v=vEkf9JgJV00
  const handleDragOver = (hde: React.DragEvent<HTMLDivElement>) => {
    hde.preventDefault();
  };

  // @ts-ignore
  const createAndSaveSignal = async (data, newFileName) => {
    try {
      setIsLoading(true);
      let reqUrl: string = `${BACKEND_URL}/r/signal/create`;
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          roomId: joinedRoomId,
          type: data.type,
          sdp: data.sdp,
          // @ts-ignore
          filename: newFileName,
        }),
      };
      const response = await fetch(reqUrl, options);
      const textData = await response.text();
      const bodyData = JSON.parse(textData);
      // console.log(bodyData);
      if (response.status === 201) {
        setFileURL(`${window.location.host}/f/${joinedRoomId}`);
      }
    } catch (error) {
      console.log(error);
      setFileURL(null);
    }finally{
      setIsLoading(false);
    }
  };

  // @ts-ignore
  const uploadFileForPeer = (uploadANewFile) => {
    if (!uploadANewFile) return setError('Please upload a file first!');
    if (error !== '') return;
    // @ts-ignore
    setUploadedFilename(uploadANewFile.name);
    setIsLoading(true);
    // @ts-ignore
    const peer = new SimplePeer({
      initiator: true,
      trickle: false,
      config: peerConfig,
    });
    // @ts-ignore
    peer.on('signal', (data) => {
      console.log(
        '4. Peer event 1 - Sending signal - should be offer - ',
        data
      );

      setIsLoading(false);

      // Using http request and saving signal instead
      // @ts-ignore
      socket.emit('send-file-offer-signal', {
        roomId: joinedRoomId,
        signal: data,
        // @ts-ignore
        uploadedFilename: uploadANewFile.name,
      });

      createAndSaveSignal(data, uploadANewFile.name);
    });
    peer.on('connect', async () => {
      console.log('5. Peer event 2 - Connecting', uploadANewFile);

      // @ts-ignore
      let buffer = await uploadANewFile.arrayBuffer();
      const chunkSize = 16 * 1024;
      while (buffer.byteLength) {
        const chunk = buffer.slice(0, chunkSize);
        buffer = buffer.slice(chunkSize, buffer.byteLength);
        // Off goes the chunk!
        peer.send(chunk);
      }
      peer.send('EOF');
    });

    peer.on('close', () => {
      console.log('Connection with peer closed :(');
      setIsLoading(false);
      return (peerInstance.current = null);
    });

    peer.on('error', (err) => {
      setIsLoading(false);
      setError(JSON.stringify(err));
    });

    console.log('5.1 peer event ', peer);

    // @ts-ignore
    peerInstance.current = peer;
  }


  const validateFileSizeAndSet = (val_file: any) => {
    const maxSize = 1024 * 1024 * 100; // 100 MB

    // if(!val_file) return setError('No file selected');
    if (!val_file) {
      setError('No file selected.');
      window.alert('No file selected.');
      return false;
    }
    // if(val_file.size > maxSize) return setError('You can not upload more than 100 MB at a time.');
    if (val_file.size > maxSize) {
      setError('You can not upload more than 100 MB at a time.');
      window.alert('You can not upload more than 100 MB at a time.');
      return false;
    }
    setError('');
    setUploadedFile(val_file);
    setUploadedFilename(val_file.name);
    return true;
  };

  const handleDropHandler = (hde: React.DragEvent<HTMLDivElement>) => {
    hde.preventDefault();
    if (hde.dataTransfer.files && hde.dataTransfer.files[0]) {
      const validated = validateFileSizeAndSet(hde.dataTransfer.files[0]);
      if (validated) {
        uploadFileForPeer(hde.dataTransfer.files[0]);
      }
    }
  };

  const handleFileChange = (fce: React.ChangeEvent<HTMLInputElement>) => {
    fce.preventDefault();
    if (fce.target.files && fce.target.files[0]) {
      const validated = validateFileSizeAndSet(fce.target.files[0]);
      if (validated) {
        uploadFileForPeer(fce.target.files[0]);
      }
    }
  };

  const handleFileExplorer = (fee: React.SyntheticEvent) => {
    fee.preventDefault();
    // @ts-ignore
    inputEl.current.click();
  };

  /*
  // @ts-ignore
  const downloadBlobFile = (blobData, new_file_name: string) => {
    const link = document.createElement('a');
    link.href = blobData;
    link.download = new_file_name;
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blobData);
    anchor.download = new_file_name;
    anchor.textContent = 'XXXXXXX';

    if (anchor.click) {
      anchor.click();
    } else {
      var evt = document.createEvent('MouseEvents');
      evt.initMouseEvent(
        'click',
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      anchor.dispatchEvent(evt);
    }
  };
  */



  const handleFileSend = (fse: React.FormEvent<HTMLFormElement>) => {
    fse.preventDefault();
    /*
    if (!uploadedFile) return setError('Please upload a file first!');
    if (error !== '') return;
    // @ts-ignore
    setUploadedFilename(uploadedFile.name);
    // @ts-ignore
    const peer = new SimplePeer({
      initiator: true,
      trickle: false,
      config: peerConfig,
    });
    // @ts-ignore
    peer.on('signal', (data) => {
      console.log(
        '4. Peer event 1 - Sending signal - should be offer - ',
        data
      );

      // Using http request and saving signal instead
      // @ts-ignore
      socket.emit('send-file-offer-signal', {
        roomId: joinedRoomId,
        signal: data,
        // @ts-ignore
        uploadedFilename: uploadedFile.name,
      });

      createAndSaveSignal(data);
    });

    peer.on('connect', async () => {
      console.log('5. Peer event 2 - Connecting', uploadedFile);

      // @ts-ignore
      let buffer = await uploadedFile.arrayBuffer();
      const chunkSize = 16 * 1024;
      while (buffer.byteLength) {
        const chunk = buffer.slice(0, chunkSize);
        buffer = buffer.slice(chunkSize, buffer.byteLength);
        // Off goes the chunk!
        peer.send(chunk);
      }
      peer.send('EOF');
    });

    peer.on('close', () => {
      console.log('Connection with peer closed :(');
      return (peerInstance.current = null);
    });

    peer.on('error', (err) => {
      setError(JSON.stringify(err));
    });

    console.log('5.1 peer event ', peer);

    // @ts-ignore
    peerInstance.current = peer;
    */
  };

  /*
  const handleDownloadFile = (dfe: React.SyntheticEvent) => {
    dfe.preventDefault();
    setGettingOffer(false);
    // @ts-ignore
    const peer = new SimplePeer({
      initiator: false,
      trickle: false,
      config: peerConfig,
    });
    // @ts-ignore
    peer.on('signal', (data) => {
      console.log('7. Peer event 3 - should be answer', {
        signal: data,
        joinedRoomId,
      });
      // @ts-ignore
      socket.emit('answer-the-offer', { signal: data, roomId: joinedRoomId });
    });

    peer.on('connect', () => {
      setGettingOffer(true);
      console.log('9. Peer event 4 - connect - ');
    });

    // @ts-ignore
    const fileChunks = [];
    // @ts-ignore
    peer.on('data', (data) => {
      if (data.toString() === 'EOF') {
        // Once, all the chunks are received, combine them to form a Blob
        // @ts-ignore
        const downloadfile = new Blob(fileChunks);
        console.log(
          '8. Peer event 5 - file as new blob - new we need to download the file ',
          downloadfile
        );
        // @ts-ignore
        downloadBlobFile(downloadfile, uploadedFilename);
      } else {
        // Keep appending various file chunks
        fileChunks.push(data);
      }
    });

    console.log(
      '6. Peer event 6 - signaling with peer signal offer',
      peerSignal
    );

    // @ts-ignore
    peer.signal(peerSignal);
    // @ts-ignore
    peerInstance.current = peer;
  };
  */

  const handleClearFile = (cfe: React.SyntheticEvent) => {
    cfe.preventDefault();
    setIsLoading(true);
    setUploadedFilename('');
    peerInstance.current = null;
    setUploadedFile(null);
    // @ts-ignore
    socket.emit('clear-send-file', joinedRoomId);
    setIsLoading(false);
  };

  useEffect(() => {
    const roomId = searchParams.get('roomId');

    // console.log({roomId});
    if (roomId) {
      // Join to the room
      setJoinedRoomId(roomId);
      // @ts-ignore
      socket.emit('connect-user-from-client', roomId);
    } else {
      // create room id and join
      // const newRoomId = crypto.randomUUID();
      const newRoomId = generateID();
      // searchParams.set('roomId', newRoomId);
      // setSearchParams(searchParams);
      setJoinedRoomId(newRoomId);
      // @ts-ignore
      socket.emit('join-room-from-client', newRoomId);
    }

    // @ts-ignore
    socket.on('estanblish-connection-from-server', () => {
      // findRemoteUserByEmailAddress(email).catch((err) => console.log(err));
      setMultiUserConnection(true);
    });

    /**
     * Using http request for this     
    // @ts-ignore
    
     */

    // @ts-ignore
    socket.on('cancel-send-file', () => {
      setUploadedFilename('');
      setUploadedFile(null);
      peerInstance.current = null;
    });

    // 2-2. ACCEPT_REQUEST
    // @ts-ignore
    socket.on('offer-is-been-accepted', (signal) => {
      console.log(
        'Accepted offer - should be answer ',
        signal,
        peerInstance.current
      );

      // @ts-ignore
      peerInstance.current.signal(signal);
    });

    // Make multi user connection false on disconnection or leave the room
  }, []);


  if(isLoading) return <Loader />

  return (
    <div className="UploadFile">
      <div className="headings">
        <h2>Share files</h2>
        <p >Drag and drop the file (under 100mb) you want to share onto the arrow</p>
      </div>
      <form className="form upload-a-file-form" onSubmit={handleFileSend}>
        <div
          className="drop-zone"
          onDragOver={handleDragOver}
          onDrop={handleDropHandler}
        >
          <input
            ref={inputEl}
            type="file"
            name="file=input"
            id="upload-file"
            hidden
            onChange={handleFileChange}
          />
          {/* @ts-ignore */}
          <button
            className="btn select-file-btn"
            onClick={handleFileExplorer}
          >
            <img src="/icons/file-upload.svg" />
          </button>
        </div>
        {uploadedFilename !== '' && (
          <div className="input-group">
            <p>{uploadedFilename}</p>
          </div>
        )}

        {/* 
        <div className="input-group">
          <button className="btn" type="submit">
              Send file
            </button> 
          {uploadedFilename !== '' && (
            <button className="btn" type="button" onClick={handleClearFile}>
              Clear
            </button>
          )}
        </div>
            */}
      </form>
      {/* {multiUserConnection ? (
        <div className="upload-a-file-form">
          <form className="form" onSubmit={handleFileSend}>
            <div
              className="drop-zone"
              onDragOver={handleDragOver}
              onDrop={handleDropHandler}
            >
              <label htmlFor="upload-file">Drag and drop any file (up to 100mb) here that you want to share, it will then show on the other device.</label>
              <input
                ref={inputEl}
                type="file"
                name="file=input"
                id="upload-file"
                hidden
                onChange={handleFileChange}
              />
              <button
                className="btn select-file-btn"
                onClick={handleFileExplorer}
              >
                <img src="/icons/file-upload.svg" />
              </button>
            </div>
            {uploadedFilename !== '' && (
              <div className="input-group">
                <p>{uploadedFilename}</p>
              </div>
            )}

            <div className="input-group">
              <button className="btn" type="submit">
                Send file
              </button>
              {uploadedFilename !== '' && (
                <button className="btn" type="button" onClick={handleClearFile}>
                  Clear
                </button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <p className='mt-1'>Type the below address into the device you want to send the file to, this connects the devices and the option to upload the file will show.</p>
      )} */}

      {joinedRoomId && fileURL && (<React.Fragment>
        <p>Enter this below address into the other device to download the file</p>
        <div className="clipboard-copy mt-1">
          <p>{fileURL}</p>
          <button onClick={(e) => copyTextToClipboard(e, fileURL)}>
            <img src="/icons/copy.svg" alt="" />
          </button>
        </div>
      </React.Fragment>
      )}
      {/* Got roomID */}
      {/* {joinedRoomId && (
        <div>
          <p>You are connected to room ID: {joinedRoomId}</p>
        </div>
      )} */}
      {/* {gettingOffer && (
        <div className="mt-1">
          {uploadedFilename && <p>{uploadedFilename}</p>}
          <button className="btn download-btn" onClick={handleDownloadFile}>
            Download
          </button>
        </div>
      )} */}
    </div>
  );
}

export default UploadFile;
