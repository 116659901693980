import React, { useEffect, useState, useRef, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import SimplePeer from 'simple-peer';
import { BACKEND_URL } from '../utils/keys';
import { peerConfig } from '../config/WebRTCPeer';
import { useSocket } from '../context/SocketProvider';
import Loader from '../components/Loader';

function ReceiveFilePage() {
  const socket = useSocket();
  const { roomId } = useParams();

  const peerInstance = useRef(null);

  const [uploadedFilename, setUploadedFilename] = useState<string>('');
  const [peerSignal, setPeerSignal] = useState(null);
  const [error, setError] = useState<null | string>('');
  const [downloadSuccess, setDownloadSuccess] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchSignal = async (roomId: string) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${BACKEND_URL}/r/signal/${roomId}`);
      if (response.status === 200) {
        const textData = await response.text();
        const jsonData = JSON.parse(textData);
        // console.log(jsonData);
        // set signal
        // @ts-ignore
        setPeerSignal({ type: jsonData.signal.type, sdp: jsonData.signal.sdp });
        // set filename
        setUploadedFilename(jsonData.signal.filename);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (roomId) {
      fetchSignal(roomId);

      // @ts-ignore
      socket.emit('connect-user-from-client', roomId);
    }

    // Socket io

    // @ts-ignore
    socket.on('getting-offer-signal', ({ signal, uploadedFilename }) => {
      console.log('Peer signal should be answer - ', signal);
      setUploadedFilename(uploadedFilename);
      setPeerSignal(signal);
      // setJoinedRoomId(roomId);
    });

    // @ts-ignore
    socket.on('cancel-send-file', () => {
      setUploadedFilename('');
      peerInstance.current = null;
    });

    // @ts-ignore
    socket.on('offer-is-been-accepted', (signal) => {
      console.log(
        'Accepted offer - should be answer ',
        signal,
        peerInstance.current
      );

      // @ts-ignore
      peerInstance.current.signal(signal);
    });
  }, []);

  // @ts-ignore
  const downloadBlobFile = (blobData, new_file_name: string) => {
    const link = document.createElement('a');
    link.href = blobData;
    link.download = new_file_name;
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blobData);
    anchor.download = new_file_name;
    anchor.textContent = 'XXXXXXX';

    if (anchor.click) {
      anchor.click();
    } else {
      var evt = document.createEvent('MouseEvents');
      evt.initMouseEvent(
        'click',
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      anchor.dispatchEvent(evt);
    }
  };

  const handleDownloadFile = (dfe: React.SyntheticEvent) => {
    dfe.preventDefault();
    setDownloadSuccess(false);

    try {
      setIsLoading(true);
      // @ts-ignore
      const peer = new SimplePeer({
        initiator: false,
        trickle: false,
        config: peerConfig,
      });
      // @ts-ignore
      peer.on('signal', (data) => {
        console.log('7. Peer event 3 - should be answer', {
          signal: data,
          roomId,
        });
        // @ts-ignore
        socket.emit('answer-the-offer', { signal: data, roomId });
      });

      peer.on('connect', () => {
        console.log('9. Peer event 4 - connect - ');
      });

      // @ts-ignore
      const fileChunks = [];
      // @ts-ignore
      peer.on('data', (data) => {
        if (data.toString() === 'EOF') {
          // Once, all the chunks are received, combine them to form a Blob
          setIsLoading(false);
          // @ts-ignore
          const downloadfile = new Blob(fileChunks);
          console.log(
            '8. Peer event 5 - file as new blob - new we need to download the file ',
            downloadfile
          );
          setDownloadSuccess(false);
          // @ts-ignore
          downloadBlobFile(downloadfile, uploadedFilename);
        } else {
          // Keep appending various file chunks
          fileChunks.push(data);
        }
      });

      console.log(
        '6. Peer event 6 - signaling with peer signal offer',
        peerSignal
      );

      peer.on('close', () => {
        setIsLoading(false);
        console.log('Connection with peer closed :(');
        return (peerInstance.current = null);
      });

      peer.on('error', (err) => {
        setIsLoading(false);
        setError(JSON.stringify(err));
      });

      // @ts-ignore
      peer.signal(peerSignal);
      // @ts-ignore
      peerInstance.current = peer;
    } catch (rtcErr) {
      setError(JSON.stringify(rtcErr));
    }finally{
      // setIsLoading(false);
    }
  };


  if(isLoading) return <Loader />
  return (
    <div className="ReceiveFilePage page">
      <div className="container">
        <h1 className='mb-2'>Receive files</h1>
        {/* {!downloadSuccess && <p className='alert-danger'>The person who sent the file left!</p>} */}
        {error !== '' && <p className='alert-danger'>{error}</p>}
        <p>Please be patient, the larger the file the more seconds to wait after clicking Download button</p>
        <p className='mt-1'>{uploadedFilename}</p>
        <button className='btn-outline mt-1' onClick={handleDownloadFile}>Download</button>
      </div>
      {/* <Suspense fallback={<Loader />} >
      </Suspense> */}
    </div>
  );
}

export default ReceiveFilePage;
