import React from 'react'

function TermsPage() {
  return (
    <div className='TermsPage terms-text page'>
      <div className="container">
        <h1>PasteBetween.com - Terms and Conditions of Use</h1>
        <h1>19 March 2023</h1>
        <p>This disclaimer/terms of service notification are subject to change without notice.</p>
        <br />
        <ol className="list-level-1">
          <li>
            <p>Terms</p>
            <p>By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trademark law.</p>
          </li>
          <li>
            <p>Use License</p>
            <ol className="list-level-2">
              <li>Permission is granted to temporarily download one copy of the materials (information or software) on PasteBetween.com’s website for personal, non-commercial transitory viewing only. This limitation does not apply for the files that are transferred using PasteBetween.com - only for the website itself. This is the grant of a license, not a transfer of title, and under this license you may not:modify or copy the materials;use the materials for any commercial purpose, or for any public display (commercial or non-commercial);attempt to decompile or reverse engineer any software contained on PasteBetween.com web site;remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or "mirror" the materials on any other server.</li>
              <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by PasteBetween.com at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
            </ol>
          </li>
          <li>
            <p>Disclaimer</p>
            <ol className="list-level-2">
              <li>The materials on PasteBetween.com web site are provided "as is". PasteBetween.com makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, PasteBetween.com does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.</li>
            </ol>
          </li>
          <li>
            <p>Limitations</p>
            <p>In no event shall PasteBetween.com or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on PasteBetween.com Internet site, even if PasteBetween.com or a PasteBetween.com authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
          </li>
          <li>
            <p>Revisions and Errata</p>
            <p>The materials appearing on PasteBetween.com’s web site could include technical, typographical, or photographic errors. PasteBetween.com does not warrant that any of the materials on its web site are accurate, complete, or current. PasteBetween.com may make changes to the materials contained on its web site at any time without notice. PasteBetween.com does not, however, make any commitment to update the materials.</p>
          </li>
          <li>
            <p>Links</p>
            <p>PasteBetween.com has not reviewed all the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by PasteBetween.com of the site. Use of any such linked web site is at the user's own risk.</p>
          </li>
          <li>
            <p>Site Terms of Use Modifications</p>
            <p>PasteBetween.com may revise these terms of use for its web site at any time without notice. By using this web site, you are agreeing to be bound by the then current version of these Terms and Privacy Policy.</p>
          </li>
          <li>
            <p>Governing Law</p>
            <p>Any claim relating to PasteBetween.com’s web site shall be governed by the laws of England and Wales without regard to its conflict of law provisions.</p>
          </li>
          <li>
            <p>Files</p>
            <p>PasteBetween.com may not be used to transfer any illegal or copyrighted material.</p>
          </li>
          <li>
            <p>Responsibility</p>
            <p>You have sole responsibility for all User Files that you store on PasteBetween.com servers through use of the Services. You acknowledge and agree that PasteBetween.com will not be responsible for any failure of the Services to store a User File, for the deletion of a User File stored on the server, or for the corruption of or loss of any data, information or content contained in a User File.</p>
          </li>
          <li>
            <p>Reliability</p>
            <p>PasteBetween.com cannot guarantee the availability of our service. Even though we have almost no downtime, we cannot be held responsible for unavailability of our service.</p>
          </li>
          <li>
            <p>Changes in TOS</p>
            <p>PasteBetween.com reserves the right to revise its policies at any time without notice.</p>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default TermsPage