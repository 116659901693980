import React, { useContext, createContext, useMemo } from 'react';
import io from 'socket.io-client';
import { BACKEND_URL_WS } from '../utils/keys';

const SocketContext = createContext(null);

export function useSocket() {
  return useContext(SocketContext);
}

function SocketProvider({ children }: { children: React.ReactNode }) {
  const socket = useMemo(() => io(BACKEND_URL_WS), []);

  return (
    // @ts-ignore
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}

export default SocketProvider;



