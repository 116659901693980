import React from 'react';

function NotFoundPage() {
  return (
    <div className='page'>
      <h1>404 Not Found</h1>
      <a href="/">Back to home</a>
    </div>
  );
}

export default NotFoundPage;
