import React, { useEffect, useState } from 'react';
import { useDetectAdBlock } from 'adblock-detect-react';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';

function HomeLayout() {
  const [blockedAd, setBlockedAd] = useState<boolean>(false);
  const [bodyWidth, setBodyWidth] = useState<number>(0);

  /*
  // THIS METHOD IS NOT WORKING
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (adBlockDetected) {
      // window.alert("ad block detected");
      setBlockedAd(true);
    }
    setTimeout(() => {
      const allAds = document.querySelectorAll('.ads');
      allAds.forEach((ad) => {
        // @ts-ignore
        if (ad.offsetHeight === 0) {
          setBlockedAd(true);
          // alert('Ad-blocker detected!');
        }
      });
    }, 1000);
  }, []);
  */


  const observeBodySize = ()=>{
    const bodyElement = document.body;
    const resizeObserver = new ResizeObserver((elements)=>{
      for (const element of elements){
        // console.log(element.contentBoxSize, element.contentRect.width);
        setBodyWidth(element.contentRect.width);
      }
    });

    resizeObserver.observe(bodyElement);
  }

  const detectAdBlocker = () => {
    const adStr = `
    <div id="dclk-studio-creative_1679246007084" style="display: inline-block; vertical-align: top;" class='ad-wrapper adsbygoogle' >
        <div style="position: relative; width: 300px; height: 250px; box-sizing: content-box;">
            <iframe data-creative-load-listener="" id="200_276_express_html_inpage_0.if"
                src="https://s0.2mdn.net/sadbundle/2179289294609317888/D22201 RAKBANK Digital Onboarding Campaign Mobile Cash 300x250/D22201 RAKBANK Digital Onboarding Campaign Mobile Cash 300x250.html"
                width="300" height="250" frameborder="0" scrolling="no" allowfullscreen="true"
                style="width: 300px; height: 250px;"></iframe>
        </div>
    </div>
    `;
    const domParser = new DOMParser();
    const adEl = domParser.parseFromString(adStr, 'text/html').body.firstChild;
    // @ts-ignore
    document.body.appendChild(adEl);

    setTimeout(() => {
      const findAdEl = document.querySelector('.ad-wrapper');
      const findAdSpaceEl = document.querySelector('.ad-space');
      // console.log(findAdEl, window.getComputedStyle(findAdEl), findAdEl.clientHeight);
      // get all css properties of the element
      // @ts-ignore
      const adStyles = window.getComputedStyle(findAdEl);
      // @ts-ignore
      const adSpaceStyles = window.getComputedStyle(findAdSpaceEl);


      if (
        !findAdEl ||
        adStyles.display === 'none' ||
        findAdEl.clientHeight === 0 ||
        findAdEl.clientWidth === 0 ||
        !findAdSpaceEl ||
        adSpaceStyles.display === 'none' ||
        findAdSpaceEl.clientHeight === 0 ||
        findAdSpaceEl.clientWidth === 0
      ) {
        console.log('Ad blocker detected');
        setBlockedAd(true);
      } else {
        console.log('Ad blocker not detected');
        setBlockedAd(false);
      }
      if (findAdEl) {
        // @ts-ignore
        findAdEl.remove();
      }
    }, 1000);
  };

  useEffect(() => {
    observeBodySize();
    detectAdBlocker();
  }, []);

  return (
    <div className="HomeLayout">
      <header>
        {/* <nav>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <Link to="/#text">Text</Link>
            </li>
            <li>
              <a href="/">Send File</a>
            </li>
          </ul>
        </nav> */}
        <div className="container">
          <a href="/">
            <h1>PasteBetween.com</h1>
          </a>
          <p>Copy and Paste text and files between phones or computers</p>
        </div>
      </header>
      {blockedAd ? (
        <div className="container">
          <p className="alert-danger">Disable ads blocker and enjoy!</p>
        </div>
      ) : (
        <React.Fragment>
          <Outlet context={{ hello: 'hello' }} />
          <div className="ad-space container">
            <a href="https://go.nordvpn.net/aff_c?offer_id=15&aff_id=86875&url_id=902" target='_blink'>
              <img src={bodyWidth <= 768 ? "/img/nerd-vpn-mobile-faster-than-ever.png": "/img/nerd-vpn-faster-than-ever.png"} alt="" className="banner" />
            </a>
          </div>
        </React.Fragment>
      )}
      <footer>
        <div className="container">
          <ul>
            <li>© BelieveX Ltd</li>
            <li><Link to='/terms'>Terms</Link></li>
            <li> <Link to='/privacy'>Privacy Policy</Link> </li>
            <li> <Link to='/cookie'>Cookies</Link> </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default HomeLayout;
