import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BACKEND_URL } from '../utils/keys';
import Loader from './Loader';

function TextViewer() {
  const [desc, setDesc] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { textNum } = useParams();

  useEffect(() => {
    if (textNum) {
      (async () => {
        try {
          // @ts-ignore
          setIsLoading(true);
          const response = await fetch(`${BACKEND_URL}/r/textlink/${textNum}`);
          const textData = await response.text();
          const jsonData = JSON.parse(textData);
          setDesc(jsonData.findText.desc);
        } catch (error) {
          console.log(error);
        } finally {
          // @ts-ignore
          setIsLoading(false);
        }
      })();
    }
  }, [textNum]);

  if(isLoading) return <Loader />
  return (
    <div className='TextViewer'>
      <h1>Here is your text</h1>
      {desc !== '' ? <p className='mt-1'>{desc}</p> : <p className='mt-1'>No text found!</p>}
    </div>
  );
}

export default TextViewer;
