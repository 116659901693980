import React from 'react';

function CookiePage() {
  return (
    <div className="CookiePage page terms-text">
      <div className="container">
        <h1>PasteBetween.com Cookies Policy</h1>
        <h2>What are cookies?</h2>
        <p>Cookies are small text files stored physically on the device which you use to browse PasteBetween.com website (be it a PC, tablet or mobile phone). Cookies created by PasteBetween.com can be accessed only by PasteBetween.com servers - no other service will have access to them, that's one of the basic security rules built into every browser.</p>

        <h2>What are cookies used for?</h2>
        <p> Cookies are used to store identifiers and other information which allows PasteBetween.com to work securely and reliably for you. With cookies we are able to provide mechanisms such as:</p>
        <ul className="list-level-1">
          <li>authentication of users - once you log in, a cookie will allow us to keep you logged in during your browser session.</li>
          <li>localization - cookies are one way of setting the language in which PasteBetween.com website is displayed.</li>
          <li>site features and services - some features settings are remembered in cookies - you make your choice once and we won't ask for it again.</li>
          <li>security - we sometimes use cookies to determine illegal repeated attempts of people guessing passwords, or sending extreme amounts of messages (which usually are spam).</li>
        </ul>
        <h2>Third party cookies</h2>
        <p>We also use third party cookies for several reasons. Some cookies are required for technical reasons in order for our websites and/or apps to operate properly. Other cookies enable us to track and target the interests of our users to enhance the experience on our websites and/or apps. Here is a list of 3rd party vendors which use cookies within PasteBetween.com website:</p>
        <ul className="list-level-1">
          <li>Google - for analytical purposes and for the "Contact import" feature</li>
          <li>Facebook - for analytical purposes</li>
        </ul>
        <h2>Your control over cookies</h2>
        <p> Basically PasteBetween.com is not able to run without cookies, most of our features require them. When you visit PasteBetween.com website and start using it, you grant your consent for storing cookies on your device. If you don't agree to store cookies or if you used PasteBetween.com in the past and want to opt out now - leave the website and then clear your browser cookies for PasteBetween.com domain. In addition your browser or device may offer settings that allow you to choose whether browser cookies are stored in the first place. Please refer to your browser's manual or help documentation for more information about deleting and controlling cookie behavior in your browser.</p>
      </div>
    </div>
  )
}

export default CookiePage;