import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import HomeLayout from './layout/HomeLayout';
import HomePage from './pages/HomePage';
import TextPage from './pages/TextPage';
import NotFoundPage from './pages/NotFoundPage';
import SocketProvider from './context/SocketProvider';
import ReceiveFilePage from './pages/ReceiveFilePage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import CookiePage from './pages/CookiePage';

function App() {
  return (
    <div className="App">
      {/* @ts-ignore */}
      <SocketProvider>
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<HomePage />}></Route>
            <Route path="/home" element={<HomePage />}></Route>
          </Route>
          {/* Text  */}
          <Route path="/t" element={<HomeLayout />}>
            <Route path=":textNum" element={<TextPage />} />
          </Route>
          {/* File  */}
          <Route path="/f" element={<HomeLayout />}>
            <Route path=":roomId" element={<ReceiveFilePage />} />
          </Route>
          <Route path="/terms" element={<HomeLayout />}>
            <Route index element={<TermsPage />}></Route>
          </Route>
          <Route path="/privacy" element={<HomeLayout />}>
            <Route index element={<PrivacyPage />}></Route>
          </Route>
          <Route path="/cookie" element={<HomeLayout />}>
            <Route index element={<CookiePage />}></Route>
          </Route>
          <Route path='*' element={<NotFoundPage />}/>
        </Routes>
      </SocketProvider>
    </div>
  );
}

export default App;
