import React, { Suspense } from 'react';
import TextViewer from '../components/TextViewer';
import Ads from '../components/Ads';
import Loader from '../components/Loader';

function TextPage() {
  return (
    <div className='TextPage container page'>
      <Suspense fallback={<Loader />}>
        <TextViewer />
      </Suspense>
      {/* <Ads /> */}
    </div>
  )
}

export default TextPage