import React, { useState } from 'react';
import { BACKEND_URL, BACKEND_SHORT_URL } from '../utils/keys';
import { copyTextToClipboard } from '../utils/helpers';
import Loader from './Loader';

function LinkMaker() {
  const [urlText, setUrlText] = useState<string>('');
  const [textLink, setTextLink] = useState<string>('');
  const [urlLink, setUrlLink] = useState<string>('');
  const [isText, setIsText] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const textLinkSubmitHandler = async (tle: React.FormEvent) => {
    tle.preventDefault();

    if (urlText === '') return;

    // Check link or text
    const pattern = /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const wwwpattern = /^w{3}.[^\s/$.?#].[^\s]*$/i;
    try {
      // @ts-ignore
      setIsLoading(true);
      let reqUrl: string = `${BACKEND_URL}/r/textlink`;
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ linkText: urlText }),
      };
      if (pattern.test(urlText) || wwwpattern.test(urlText)) {
        console.log('Link');
        // /r/shorturl
        reqUrl = `${BACKEND_URL}/r/shorturl`;
      } else {
        console.log('Not link');
      }
      const response = await fetch(reqUrl, options);
      const textData = await response.text();
      const bodyData = JSON.parse(textData);
      console.log(bodyData);

      if (bodyData.textId) {
        setIsText(true);
        setTextLink(`${window.location.origin}/t/${bodyData.num}`);
      } else if (bodyData.savedUrl.shortId) {
        setIsText(false);
        setUrlLink(`${BACKEND_SHORT_URL}/r/${bodyData.savedUrl.shortId}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    // Copy to clipboard
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="LinkMaker">
      <div className="headings">
        <h2>URL shortener and text retrieve</h2>
        <p>Insert link or text</p>
      </div>
      <form onSubmit={textLinkSubmitHandler}>
        <div className="input-group">
          {/* <label htmlFor="link_text">Insert link or text</label> */}
          <textarea
            className="form-control"
            name="link_text"
            id="link_text"
            defaultValue={urlText}
            onChange={(e) => setUrlText(e.target.value)}
            rows={10}
            tabIndex={1}
            autoFocus
          ></textarea>
        </div>
        <div className="input-group">
          <button className="btn" type="submit">
            Create Share Link
          </button>
        </div>
      </form>

      <div className="link-group">
        {isText && textLink !== '' && (
          <div className="link">
            <h2 className='mt-1'>Retrieve text</h2>
            <div className="clipboard-copy mt-1">
              <p>{textLink}</p>
              <button onClick={(e) => copyTextToClipboard(e, textLink)}>
                <img src="/icons/copy.svg" alt="" />
              </button>
            </div>
          </div>
        )}

        {!isText && urlLink !== '' && (
          <div className="link">
            <h5>URL</h5>
            <div className="clipboard-copy mt-1">
              <p>{urlLink}</p>
              <button onClick={(e) => copyTextToClipboard(e, urlLink)}>
                <img src="/icons/copy.svg" alt="" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LinkMaker;
