import React from 'react';
export const copyTextToClipboard = async (
  ctce: React.SyntheticEvent,
  textToCopy: string
) => {
  ctce.preventDefault();
  await window.navigator.clipboard.writeText(textToCopy);
  // await navigator.clipboard.writeText(textToCopy);
};


export const generateID=()=> {
  let id = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const length = characters.length;
  for (let i = 0; i < 6; i++) {
    id += characters.charAt(Math.floor(Math.random() * length));
  }
  return id;
}
