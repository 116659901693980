import React from 'react'

function PrivacyPage() {
    return (
        <div className='PrivacyPage terms-text page'>
            <div className="container">
                <h1> PasteBetween.com Privacy Policy </h1>
                <p> Your privacy is very important to us, and keeping your information confidential is at the heart of everything we do. The purpose of this Privacy Policy is to help you understand what information BelieveX Ltd, (hereafter "we" or " PasteBetween.com") collect, why we collect it and how you can manage your rights.</p>
                <br />
                <p>How we collect and use your personal data </p>
                <br />
                <p> The types of personal data that we collect and how we use that information depends on your relationship with PasteBetween.com and how you interact with us:                </p>
                <ul className='list-level-1' >
                    <li>
                        <p>When you sign up for our services, we process personal data that you provide to us such as your name, email address and payment information. We process this information to:</p>
                        <ul className='list-level-2'>
                            <li>provide you with access to our services</li>
                            <li>process payments </li>
                            <li> manage the customer relationship </li>
                            <li> communicate and market our services</li>
                            <li> prevent fraud</li>
                            <li> comply with bookkeeping obligations</li>
                        </ul>
                        <p>Our legal basis for this processing is GDPR art. 6 (1) (b) (performance of contract), art. 6 (1) (c) (legal obligation) and art. 6 (1) (f) (legitimate interest)</p>
                    </li>
                    <li>
                        <p>When you use our file transfer services, we transmit and store the content of your messages and files, as well as related metadata (time and date, file size etc.). We process this information to:</p>
                        <ul className='list-level-2'>
                            <li>provide our file transfer services to you</li>
                            <li>remove illegal content when required by law</li>
                            <li>maintain the security and integrity of our services</li>
                            <li>understand how our services are used, so that we can improve and develop our services further</li>
                        </ul>
                        <p>Our legal basis for this processing is GDPR art. 6 (1) (b) (performance of contract), art. 6 (1) (c) (legal obligation) and art. 6 (1) (f) (legitimate interest)</p>
                    </li>
                    <li>
                        <p>When you connect with our web servers, typically when you visit PasteBetween.com, we collect technical data including information about your network and device such as your IP address, operating system, web browser, cookie information as well as a referral URL if you clicked on a link to get to us. We process this information to:</p>
                        <ul className='list-level-2' >
                            <li> provide access to our services in a format that is tailored to your device</li>
                            <li> understand how our services are used, so that we can improve and develop our services further</li>
                            <li> maintain the security and integrity of our services (preventing DDOS attacks etc.)</li>
                        </ul>
                        <p>Our legal basis for this processing is GDPR art. 6 (1) (f) (legitimate interest)</p>
                    </li>
                    <li>
                        <p> When you contact our customer support channels, we store the content and associated metadata (time and date etc.) of your inquiry. We process this information to:</p>
                        <ul className='list-level-2' >
                            <li>respond to your inquiries</li>
                            <li>investigate and resolve errors, bugs and other incidents</li>
                            <li>understand how our services may be further improved and developed</li>
                        </ul>
                        <p>Our legal basis for this processing is GDPR art. 6 (1) (b) (performance of contract) and art. 6 (1) (f) (legitimate interest)</p>
                    </li>
                </ul>

                <p>How long we store your information?</p>
                <ul className='list-level-1' >
                    <li>All file transfer content is deleted when a transfer expires, however, content may be available in data backups for a brief period of time. Users with an account may access messages relating to file transfers in the message archive.</li>
                    <li>Metadata related to messages and file transfers is anonymised and kept for statistical purposes following the expiry of a transfer.</li>
                    <li>User accounts are maintained for 365 days after the end of a subscription to enable quick reactivation should you decide to return to PasteBetween.com, and will then be anonymized.</li>
                    <li>Transaction data is kept for 3,5 years after the end of the fiscal year to comply with bookkeeping regulation and to be able to resolve dispute and support cases.</li>
                    <li>
                        <p>Other data are kept for only as long as necessary to achieve the purpose(s) for which they are processed. The criteria for determining the retention period includes:</p>
                        <ul className='list-level-2'>
                            <li>the nature of your relationship with us and how the data was collected</li>
                            <li>the nature and sensitivity of the personal data</li>
                            <li>the purposes for which the personal data is being used, and</li>
                            <li>the existence of any legal obligations to retain the personal data</li>
                        </ul>
                    </li>
                    <li>
                        You may request erasure of your data at any time, but please note that you will not be able to use our services if you decide to erase your data and that we still need to keep certain records to comply with legal obligations or to establish, defend or exercise legal claims.
                    </li>
                </ul>

                <p>How your data is stored ?</p>
                <ul className='list-level-1' >
                    <li>PasteBetween.com does not store your files, they are transferred directly between devices using WebRTC Technology.
                    </li>
                </ul>

                <p>We do not disclose personal data to third parties, with the following exceptions:</p>
                <ul className='list-level-1' >
                    <li>We disclose content and messages related to our file transfer services to recipients determined by you</li>
                    <li>We may disclose information to law enforcement agencies when required by England and Wales law</li>
                </ul>


                <p>We do not share personal data with third parties for their direct marketing purposes.</p>

                <h2>Cookies</h2>
                <p>We use cookies and similar technologies such as pixel tags to recognize your device, to analyze use of our website and for marketing purposes.
                </p>
                <br />
                <p>Your rights</p>
                <br />
                <p>Under applicable data protection laws and regulations, you have the right to:</p>
                <ul className='list-level-1' >
                    <li>Be informed if your personal data is being collected and used ("Right to Information")</li>
                    <li> Request confirmation on whether we are processing your personal data, and to get copies of your data ("Right of Access")</li>
                    <li>Correct inaccurate personal data ("Right to Rectification")</li>
                    <li>Limit the way we use your data ("Right to Restriction")</li>
                    <li>Transfer certain personal data to another organization ("Right to Data Portability")</li>
                    <li>Object to the use or processing of personal data in some circumstances ("right to object")</li>
                </ul>
                <p>To exercise any of your rights, please contact support@pastebetween.com. To facilitate the efficient handling of your rights, we ask that you are as specific as possible when stating how you want to exercise your rights.</p>
                <br />
                <p>PasteBetween.com does not currently respond to "Do Not Track" signals from your browser, as no formal standard on how to respond to such signals has been adopted.</p>

                <h2>Contact</h2>
                <br />
                <p>If you have questions about the collection, use or other processing of your personal data, including exercising your individual rights, please contact us at support@pastebetween.com</p>

                <br />
                <p>PasteBetween.com</p>
                
                
                
                <p>MH Associates, Barbican House</p>
                <p>36 New St</p>
                <p>Plymouth</p>
                <p>PL1 2NA</p>
                <p>United Kingdom</p>

            </div>
        </div>
    )
}

export default PrivacyPage